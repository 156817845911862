import axios from "axios";
import UserService from "./UserService";

const base_api = 'https://qa.tria.connectria.com';

const OrganizationService = {
  
  customer_type: function(params = {}){
    let request = axios.get(
      base_api + "/plugin/tria_interface/customer_type",
      params
    );
    return request;
  },


  customer_message: function() {
    return axios.get(base_api + `/plugin/tria_interface/customer_message`);
  },

  list_orgs: function(params = {}){
    let request = axios.get(
      base_api + "/v2/prototype/domain/organizations/get",
      params
    );
    return request;
  },

  switch_org: function(params= {}) {
    let request = axios.post(
      base_api + "/v2/prototype/domain/switch_organization",
        params.payload,
        params.headers
    );
    return request;
  },

  async switchOrganization(customer_id, divvyToken) {
    const data = {customer_id: customer_id}
    const config = {
      headers: {'x-auth-token': divvyToken}
    }

    try {
      const resp = await axios.post(base_api + "/plugin/tria_interface/switch-organization-new", data, config)
      return {success: resp.data.success}
    } catch (err) {
      console.error('Error switch Divvy organization', err)
      return {success: false, error: err}
    }
  }
};

export default OrganizationService;
