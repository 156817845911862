import axios from "axios";
import moment from "moment";
import CookieService from "../services/CookieService";
const backup_api = 'https://4wzv4f0oof.execute-api.us-east-1.amazonaws.com/dev';
import Cognito from "../utility/CognitoAuth";
import ConnectriaTicketsService from "./ConnectriaTicketsService";
async function defaultRequest() {
  const idToken = await Cognito.getIdToken();
  let req = axios.create({
    baseURL: backup_api,
    headers: { Authorization: idToken },
  });
  //req.defaults.headers['Authorization'] = await Cognito.getIdToken();
  return req;
}

const ConnectriaBackupService = {
  getJobsApiCallBody: function () {
    let body = {
      pagingConfig: {
        sortDirection: 1,
        offset: 0,
        sortField: "jobStartTime",
        limit: 100,
      },
      jobFilter: {
        endTimeRange: {
          fromTime: moment().unix() - 604800,
          toTime: moment().unix(),
        },
        showAgedJobs: false,
        entity: {
          clientId: 0,
        },
      },
    };
    return JSON.parse(JSON.stringify(body));
  },
  getCommServeJobs: async function (body, params = {}) {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie("OrganizationId");
    let res = req.post(
     `commserve/jobs`,
      body,
      params
    );
    return res;
  },
  getCommServeJobDetails: async function (jobId, params = {}) {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie("OrganizationId");
    let res = req.get(
       `commserve/jobDetails/` + jobId,
      params
    );
    return res;
  },
  getCommServeClient: async function (id, params = {}) {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie("OrganizationId");
    let res = req.get(
       `commserve/client/` + id,
      params
    );
    return res;
  },
  getCommServeClientGroup: async function (params = {}) {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie("OrganizationId");
    let shortname = await ConnectriaTicketsService.getCustomerShortname();
    let res = req.get(
       `commserve/clientgroup?name=${shortname.shortName}&orgId=${orgId}`,
      params
    );
    return res;
  },
  getCommServeClientGroupStats: async function (clientGroupId, params = {}) {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie("OrganizationId");
    let res = req.get(
     
        `commserve/clientGroupStats?cgid=${clientGroupId}`,
      params
    );
    return res;
  },
  getCommServeSubclientDetails: async function (subclientId, params = {}) {
    const req = await defaultRequest();
    let orgId = CookieService.getCookie("OrganizationId");
    console.log(
      `commserve/subClient/` + subclientId
    );
    let res = req.get(
     `commserve/subClient/` + subclientId,
      params
    );
    return res;
  },
};

export default ConnectriaBackupService;
