import axios from "axios";

const base_api = 'https://qa.tria.connectria.com';

const PublicCloudService = {
  
  resource_query: function(payload, params = {}) {
    let request = axios.post(
      base_api + `/v2/public/resource/query`,
      {
        ...payload,
        ...params
      }
    );
    return request;
  },
  list_clouds: function(params = {}){
    let request = axios.get(
      base_api + "/v2/public/clouds/list",
      params
    );
    return request;
  },
  resourcegroup_list: function(params = {}) {
    let request = axios.get(
      base_api + "/v2/public/resourcegroups/list",
      params
    );
    return request;
  },
  resourcematrix_list: function(params = {}) {
    let request = axios.get(
      base_api + `/v2/public/resource-matrix`,
      params
    );
    return request;
  },
  list_clouds_filtered: function(params = {}){
    let request = axios.post(
      base_api + "/v2/public/clouds/list",
      params.payload,
      params.headers
    );
    return request;
  },
  list_badge_count_for_resources: function(params= {}) {
    let request = axios.post(
      base_api + "/v2/public/badges/count",
        params.payload,
        params.headers
    );
    return request;
  },
  cloud_types_list: function(params = {}) {
    let request = axios.get(
      base_api + '/v2/public/cloudtypes/list',
      params
    );
    return request
  },
  cloud_badges_list: function(params = {}) {
    let request = axios.get(
      base_api + '/v2/public/badge/clouds/assigned/list',
      params
    );
    return request
  }

};

export default PublicCloudService;
