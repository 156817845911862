import { useState, useEffect } from "react";
import React from "react";
const { detect } = require("detect-browser");
import { appState } from "../AppState";
const SpoofBanner = props => {
  const [stateApp, stateAppActions] = appState();
  const [showMsg, setShowMsg] = useState(false);
  const spoofEnv = "QA";

  const showSpoof = org_name =>{
    if(org_name != "Default Organization" && (stateApp.userInfo || {}).domain_admin || false) return true;
    return false;
  }

  return (
    <>
      {showSpoof((stateApp.userInfo || {}).organization_name || '') ? 
         <div className={stateApp.env == 'dev' ? 'alert-spoof-dev' : 'alert-spoof' + ' alert'} style={{padding: 0, paddingBottom: 10 + 'px', paddingTop: 10 + 'px'}} role="alert">
         <p className="h4 text-center" style={{color: 'white', marginBottom: 0}}>
          You are viewing <span style={{fontWeight: 800, fontSize: 1.2 + 'rem'}}>{(stateApp.userInfo || {}).organization_name || ''}</span> in {spoofEnv} as User <span style={{fontWeight: 800}}>{stateApp.userInfo.username}</span>
         </p>
        
       </div>
       : <></>
       
      }
    </>
  );
};
export default SpoofBanner;
